import { Content, Flex, Grid, Heading, View } from '@adobe/react-spectrum';
import { Temporal } from '@js-temporal/polyfill';
import { useHover } from '@react-aria/interactions';
import { useBreakpoint } from '@react-spectrum/utils';
import Briefcase from '@spectrum-icons/workflow/Briefcase';
import Building from '@spectrum-icons/workflow/Building';
import Date from '@spectrum-icons/workflow/Date';
import Location from '@spectrum-icons/workflow/Location';
import Money from '@spectrum-icons/workflow/Money';
import escapeRegExp from 'lodash.escaperegexp';
import mem from 'mem';
import Image from 'next/image';
import Link from 'next/link';

import { Job } from '../pages';

export const memoizedGetPostedText = mem((job: Job) => {
  const daysSincePost =
    Temporal
      .PlainDate
      .from({ day: +job.date.split('-')[2], month: +job.date.split('-')[1], year: +job.date.split('-')[0] })
      .until(Temporal.Now.plainDateISO())
      .days;

  return `Posted ${daysSincePost === 0 ? 'today' : daysSincePost === 1 ? `${daysSincePost} day ago` : `${daysSincePost} days ago`}`;
}, { cacheKey: JSON.stringify });

const highlightText = (text: string, highlightedText: string) => {
  const highlightedTextStartIndex = text.toLowerCase().search(escapeRegExp(highlightedText.toLowerCase()));

  if (highlightedTextStartIndex === -1) return text;

  const highlightedTextEndIndex = highlightedTextStartIndex + highlightedText.length;

  return (
    <>
      {text.substring(0, highlightedTextStartIndex)}
      <mark>
        {text.substring(highlightedTextStartIndex, highlightedTextEndIndex)}
      </mark>
      {text.substring(highlightedTextEndIndex)}
    </>
  );
};

interface Props {
  job: Job;
  searchText: string;
}

const JobPost = ({
  job,
  searchText
}: Props) => {
  const { matchedBreakpoints } = useBreakpoint();
  const isInBaseBreakpointRange = !matchedBreakpoints.includes('S');
  const { hoverProps, isHovered } = useHover({});

  return (
    <Link
      key={job.id}
      href={`/jobs/${job.id}`}
      passHref
    >
      <a
        className="link"
        {...isInBaseBreakpointRange && { style: { minWidth: 0 } }}
        {...hoverProps}
      >
        <View
          UNSAFE_style={{ transition: 'border-color var(--spectrum-global-animation-duration-100) ease-in-out' }}
          backgroundColor="gray-50"
          borderColor={isHovered ? 'dark' : 'light'}
          borderRadius="regular"
          borderWidth="thin"
          padding={{ base: 'size-400', S: 'size-0' }}
        >
          <Grid
            columns={{ S: ['auto', '1fr'] }}
            justifyItems={{ base: 'center', S: 'stretch' }}
          >
            {job.companyLogoFileName ? (
              <View
                height={{ base: 'size-2000', S: 'size-1700' }}
                position="relative"
                width={{ base: 'size-2000', S: 'size-1700' }}
                {...isInBaseBreakpointRange ? {
                  borderColor: 'light',
                  borderRadius: 'regular',
                  borderWidth: 'thin',
                  marginBottom: 'size-200'
                } : {
                  borderBottomStartRadius: 'regular',
                  borderTopStartRadius: 'regular'
                }}
              >
                <Image
                  alt={`${job.companyName} logo`}
                  layout="fill"
                  objectFit="contain"
                  quality={100}
                  src={`https://f000.backblazeb2.com/file/illustration-jobs/jobs/company-logos/${job.companyLogoFileName}`}
                  style={{
                    ...isInBaseBreakpointRange ? {
                      borderRadius: 'var(--spectrum-alias-border-radius-regular)'
                    } : {
                      borderBottomLeftRadius: 'var(--spectrum-alias-border-radius-regular)',
                      borderTopLeftRadius: 'var(--spectrum-alias-border-radius-regular)'
                    }
                  }}
                />
              </View>
            ) : (
              <View
                backgroundColor="gray-50"
                height={{ base: 'size-2000', S: 'size-1700' }}
                width={{ base: 'size-2000', S: 'size-1700' }}
                {...isInBaseBreakpointRange ? {
                  borderColor: 'light',
                  borderRadius: 'regular',
                  borderWidth: 'thin',
                  marginBottom: 'size-200'
                } : {
                  borderBottomStartRadius: 'regular',
                  borderTopStartRadius: 'regular'
                }}
              >
                <Grid
                  UNSAFE_style={{ fontSize: isInBaseBreakpointRange ? 80 : 60 }}
                  alignContent="center"
                  height="100%"
                  justifyContent="center"
                >
                  {job.title[0]}
                </Grid>
              </View>
            )}
            <View
              minWidth={{ base: 0 }}
              padding={{ S: 'size-200' }}
              {...!isInBaseBreakpointRange && {
                borderStartColor: 'light',
                borderStartWidth: 'thin'
              }}
            >
              <Flex
                columnGap={{ S: 'size-50' }}
                direction={{ base: 'column', S: 'row' }}
                justifyContent={{ S: 'space-between' }}
                marginBottom={{ base: 'size-100', S: 'size-200' }}
              >
                <Heading
                  UNSAFE_style={{
                    color: 'var(--spectrum-alias-heading-text-color)',
                    lineHeight: 'var(--spectrum-alias-heading-text-line-height)',
                    ...isInBaseBreakpointRange ? {
                      fontSize: 'var(--spectrum-alias-heading2-text-size)',
                      textAlign: 'center'
                    } : {
                      display: '-webkit-box',
                      fontSize: 'var(--spectrum-alias-heading3-text-size)',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: '1'
                    }
                  }}
                  level={3}
                  marginBottom={{ base: 'size-200', S: 'size-0' }}
                  marginTop="size-0"
                >
                  {highlightText(job.title, searchText)}
                </Heading>
                <Flex
                  UNSAFE_style={{
                    color: 'var(--spectrum-global-color-gray-700)',
                    fontSize: 'var(--spectrum-alias-heading6-text-size)'
                  }}
                  alignItems="center"
                  flexShrink={0}
                  justifyContent={{ base: 'center', S: 'stretch' }}
                >
                  <Date
                    marginEnd="size-100"
                    size="S"
                  />
                  {memoizedGetPostedText(job)}
                </Flex>
              </Flex>
              <Content>
                <Grid
                  columns={{ S: ['1fr', '1fr'] }}
                  justifyItems={{ base: 'center', S: 'stretch' }}
                  rowGap="size-100"
                >
                  <Flex
                    UNSAFE_style={{
                      color: 'var(--spectrum-global-color-gray-700)',
                      fontSize: 'var(--spectrum-alias-heading6-text-size)'
                    }}
                    alignItems="center"
                  >
                    <Building
                      marginEnd="size-100"
                      size="S"
                    />
                    <div>
                      {highlightText(job.companyName, searchText)}
                    </div>
                  </Flex>
                  <Flex
                    UNSAFE_style={{
                      color: 'var(--spectrum-global-color-gray-700)',
                      fontSize: 'var(--spectrum-alias-heading6-text-size)'
                    }}
                    alignItems="center"
                  >
                    <Location
                      marginEnd="size-100"
                      size="S"
                    />
                    <div>
                      {highlightText(job.location, searchText)}
                    </div>
                  </Flex>
                  <Flex
                    UNSAFE_style={{
                      color: 'var(--spectrum-global-color-gray-700)',
                      fontSize: 'var(--spectrum-alias-heading6-text-size)'
                    }}
                    alignItems="center"
                  >
                    <Briefcase
                      marginEnd="size-100"
                      size="S"
                    />
                    {job.type}
                  </Flex>
                  <Flex
                    UNSAFE_style={{
                      color: 'var(--spectrum-global-color-gray-700)',
                      fontSize: 'var(--spectrum-alias-heading6-text-size)'
                    }}
                    alignItems="center"
                  >
                    <Money
                      marginEnd="size-100"
                      size="S"
                    />
                    {job.salary}
                  </Flex>
                </Grid>
              </Content>
            </View>
          </Grid>
        </View>
      </a>
    </Link>
  );
};

export default JobPost;
